/*
 *  modal.css
 *  Solitaire
 *
 *  Created by Kieran Hannagan & Kim Moran on September 30th, 2022
 *  Copyright 2022 Sinclair Digital. - All Rights Reserved
 *
 *  Styling for our modal including accordion menu, table, tooltip, checkboxes
 */
/* ---------------------------------------------------------------- */

:root {
    --heading-color: rgb(125, 175, 190);
    --description-title-color: rgb(0, 0, 0);
    --default-text-color: #333333;
    --bold-text-color: rbg(0, 0, 0) --background-color-2: #FFFFFF;
    --background-color-3: #FFFFFF;
    --round-border: 10px;
    --heading-color: #0075FF;
    /* for the feedback button and wikipedia link */
    --green-go: #04B000;
}

/* ---------------------------------------------------------------- */
/*
*   MODAL STYLING
*/
/* ---------------------------------------------------------------- */

* {
    font-family: "Inter", "Arial, Helvetica, sans-serif";
    font-size: 12px;
}

.show-modal.modal {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    position: fixed;
    left: 50%;
    top: 20%;
    transform: translate(-50%, 0%);
    z-index: 1050;
    display: none;
    /*height: 100%;*/
    width: 385px;
    outline: 0;
}

.modal-dialog {
    position: relative;
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    background-color: transparent;
   /* height: 100%;*/
    width: 385px;
    border: none;
    border-radius: 10px;
    filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.25));
    outline: 0;
    overflow-y: auto;
    overflow-x: hidden;
}

.modal-title {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 135%;
    color: #333333;
}

.modal-body {
    padding: 0rem 1rem;
    background-color: white;
    border-radius: 10px;
}

.modal-body h1 {
    margin-top: 0;
}


.modal-title .blank {
    width: 27px;
    font-size: 1rem;
    flex-basis: 10%;
}

.modal-title p {
    justify-items: center;
    text-align: center;
    font-size: 15px;
    flex: 1;
    flex-basis: 80%;
    color: var(--default-text-color);
}

.modal-title button {
    background-color: transparent;
    border: none;
}

.modal-title .close-icon,
.closed-icon {
    cursor: pointer;
    flex-basis: 10%;
    justify-items: flex-end;
    color: var(--default-text-color)
}

.modal-btn {
    color: var(--default-text-color);
    padding: .5rem 1rem;
    font-weight: 700px;
    width: 120px;
    height: 30px;

    background: linear-gradient(180deg, rgba(103, 182, 255, 0.5) 0%, #67B6FF 100%);
    border-radius: 8px;
}

.modal-btn:hover {
    cursor: pointer;
    background: linear-gradient(180deg, rgba(103, 182, 255, 0.4)0%, #67B6FF 80%);
    border-radius: 8px;
}

.green-modal-btn {
    color: white;
    padding: .5rem 1rem;
    font-weight: 800px;
    width: 120px;
    height: 30px;

    background: linear-gradient(0deg, rgba(6, 124, 66, 1) 0%, rgba(14, 207, 114, 1) 100%);
    border-radius: 8px;
}

.green-modal-btn:hover {
    cursor: pointer;
    background: linear-gradient(0deg, rgba(6, 124, 66, 1)0%, rgba(14, 207, 114, 1) 80%);
    border-radius: 8px;
}

.caution {
    justify-content: left;
    background: linear-gradient(180deg, #FFC30F 0%, #FFE186 100%);
    border-radius: 8px;
}

.caution:hover {
    background: linear-gradient(180deg, rgb(255, 195, 15, .8) 0%, rgb(255, 225, 134, .8) 100%);
}

.warning {
    text-align: center;
    font-weight: 700;
    color: black !important;
}

#warning-text {
    text-align: center;
    color: black;
}

.image-button {
    padding: 0;
    border: none;
    background: none;
}
/* ---------------------------------------------------------------- */
/*
*  SLIDE LEFT ANIMATION
*/
/* ---------------------------------------------------------------- */

.modal-dialog {
    opacity: 0;
    transform:none;
    /*transform: translateX(100%);*/
   /* transition: all 0.5s var(--bounceEasing);*/
}

.animate-modal .modal-dialog {
    opacity: 1;
    transform: none;
   /* transition-delay: 0.2s;
    transform: scale(1);
    transition:
        transform 0.1s cubic-bezier(calc(1 - 0.465), calc(1 - 0.183), calc(1 - 0.153), calc(1 - 0.946)),
        opacity 0.1s cubic-bezier(calc(1 - 0.465), calc(1 - 0.183), calc(1 - 0.153), calc(1 - 0.946))*/
}


.difficulty-options {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 1rem;
}

.center {
    margin: 0;
    position: relative;
   
    left: 50%;
    -ms-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
  }
/* ---------------------------------------------------------------- */
/*
*   DECK OPTION STYLING
*/
/* ---------------------------------------------------------------- */

.deck-options {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 1rem;
}

.mobile-card {
    display: none;
}

.active-card-boxes {
    height: 90px;
    width: 65px;
    background: #D9D9D9;
    border: 2px solid var(--green-go);
    border-radius: 10px;
    cursor: pointer;
}

.inactive-card-boxes {
    height: 90px;
    width: 65px;
    background: #D9D9D9;
    border-radius: 10px;
    opacity: 50%;
    cursor: pointer;
}
.active-card {
    border: 2px solid rgba(0, 0, 0, 0.0);
    border-radius: 10px;
    cursor: pointer;
}

.inactive-card{
  
    border: 2px solid rgba(0, 0, 0, 0.0);
    border-radius: 10px;
    opacity: 50%;
    cursor: pointer;
}
.active-card:hover {

    border: 2px solid var(--green-go);
    
}

.inactive-card:hover{
  
    opacity: 100%;
    border: 2px solid var(--green-go);
}

.active-card-images {
    height: 86px;
    width: 61px;
    border: 2px solid rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.inactive-card-images {
    height: 86px;
    width: 61px;
    border: 2px solid rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    background: linear-gradient(163.89deg, rgba(255, 255, 255, 0) 47.82%, #FFFFFF 47.83%, rgba(255, 255, 255, 0) 64.45%);
}

.active-card-images>img {
    height: 86px;
    width: 61px;
    border-radius: 10px;
}

.inactive-card-images>img {
    height: 86px;
    width: 61px;
    border-radius: 10px;
}

.remove {
    display: none;
}

/* ---------------------------------------------------------------- */
/*
*   ACCORDION STYLING
*/
/* ---------------------------------------------------------------- */

h1 {
    font-size: 50px;
    color: var(--heading-color);
    margin-bottom: 0;
}

.main-content {
    margin: 5px 15px 5px 15px;
}

.description-title {
    text-align: center;
    background-color: var(--background-color-2);
    line-height: 46px;
    border-radius: var(--round-border);
    transition: 0.3s;
    color: var(--default-text-color);
}

.expand-collapse {
    float: right;
    margin-right: 8px;
}

.description {
    margin-left: 0px;
    transition: 0 0.2s ease-out;
    color: var(--default-text-color);
}

.description>p {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.modal-footer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding: 1rem;
}

/* 


Stylings for Settings


 */

 .menuContainer {
	position: absolute;
	display: none;
	justify-content: center;
	align-items: center;
	/* border: 5px solid #CD853F; */
	/* width: 50%; */
	height: 50%;
	border-radius: 15px;
}

.menuBox {
	background-color: #220B00;
	border: 5px solid #CD853F;
	border-radius: 10px;
	opacity: 95%;
	height: 70%;
	width: 37.5%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: top;
}

.menuBox, .menuContent {
	height: 100%;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	/* border: 3px solid purple; */
} 

.menuBox, .menuContent, .resultDisplay {
	padding-top: 5%;
	/* display: none; */
	width: 100%;
	height: 12.5%;
	text-align: center;
	align-items: center;
	color: #CD853F;
	font-weight: 500;
	/* border: 2px solid blue; */
}

.menuBox, .menuContent, .menuButtonList {
	padding-top: 1%;
	z-index: 1;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
	height: 67.5%;
	list-style-type: none;
	/* border: 2px solid red; */
}

.menuBox, .menuContent, .menuButtonList li {
	width: 66%;
	height: 20%;
	display: flex;
	justify-content: center;
	/* border: 2px solid green */
}

.menuBox, .menuContent, .menuButtonList, li button {
	height: 100%;
	width: 100%;
	background-color: #CD853F;
	color: white;
	text-shadow: 2px 2px 2.5px black, -0.5px -0.5px 0px black, -0.5px 0.5px 0px black, 0.5px -0.5px 0px black, 0.5px 0.5px 0px black;
	border: none;
	border-radius: 10px;
	transition: ease-out 125ms;
}

.menuBox, .menuContent, .menuButtonList, li button:hover {
	transition: ease-in 125ms;
	background-color: burlywood;
	border: none;
	border-radius: 10px;
}


@media screen and (min-width: 200px) and (max-width: 499px) and (min-height: 0px) and (max-height: 940px),
screen and (min-width:500px) and (max-width:940px) and (min-height:0px) and (max-height:499px) {

    .modal {
        top: 0px;
    }

    .show-modal.modal {
        display: flex;
        /* flex-direction: column; */
        justify-content: center;
        align-items: center;
    }

    .description {
        /* font-size: 14px; */
    }

    .main-content {
        margin: 5px 2% 5px 2%;
        box-shadow: none;
    }

    .modal-body {
        border-radius: 0px;
        overflow-y: auto;
    }

    .modal-dialog {
        border-radius: 0px;
        height: 100%;
        width: 100%;
        overflow-y: auto;
        opacity: 0;
        transform:none;
        transform: translateY(100%);
        transition: all 0.5s var(--bounceEasing);
    }

    .modal-footer {
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 1rem;
        padding: 1rem;
    }
}

/* ---------------------------------------------------------------- */
/*
*   TABLE STYLING
*/
/* ---------------------------------------------------------------- */
.divider {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.33);
}

.styled-table {
    border-collapse: collapse;
    margin: auto;
    font-family: sans-serif;
    margin: 1rem 0;
}

.styled-table thead tr {
    background-color: var(--background-color-2);
    color: (var(--default-text-color));
    text-align: left;
    line-height: 12px;
}

.description .styled-table {
    width: 100%;
}

.styled-table th,
.styled-table td {
    line-height: 12px;
}

td {
    height: 24px;
}

.rules-section>.styled-table tbody tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.styled-table tbody tr {
    background-color: var(--background-color-2);
    width: 100%;
}

.styled-table tbody tr.active-row {
    font-weight: bold;
    color: rgb(0, 0, 0);
}

@media (max-width: 768px) {

    .styled-table {
        border-collapse: collapse;
        margin: auto;
        min-width: 275px;
        font-family: sans-serif;
        max-width: 100%;
    }

    .styled-table td {
        padding: 1rem, .5rem;
    }

}

@media (max-width: 425px) {

    .styled-table {
        border-collapse: collapse;
        margin: 10px, 10px;
        min-width: 100%;
        padding: 10px 10px;
        font-family: sans-serif;
        max-width: 80%;
    }
}

/* ---------------------------------------------------------------- */
/*
*   TOOLTIP STYLING
*/
/* ---------------------------------------------------------------- */

.condition-info {
    position: relative;
    display: inline-block;
    width: 100%;
}
.tooltip {
    position: relative;
    display: inline-block;
  }
  
  /* Tooltip text */
  .tooltip .tooltiptext {
      visibility: hidden;
      background-color: rgb(11, 65, 64);
      color: white;
      text-align: left;
      border-radius: 6px;
      width: 170px;
      padding: 10px;
      margin-left: -10px;
      position: absolute;
      z-index: 1;
      border-radius: 5px;
      bottom: 150%;
      left: 0%;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
      visibility: visible;
  }

.points-info {
    text-align: right;
    justify-content: end;
}

@media (max-width: 625px) {

    .tooltip {
        border-bottom: none;
    }
}

/* ---------------------------------------------------------------- */
/*
*   CHECKBOX STYLING
*/
/* ---------------------------------------------------------------- */
.switch-wrapper {
    margin: 21px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.toggleSwitch {
    order: 1;
    display: inline-block;
    height: 20px;
    position: relative;
    overflow: visible;
    padding: 0;
    cursor: pointer;
    width: 40px;
    background-color: var(--green-go);
    border-radius: 20px;
    height: 20px;
}

.toggleSwitch * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.toggleSwitch input:focus~a,
.toggleSwitch input:focus+label {
    outline: none;
}

.toggleSwitch label {
    position: relative;
    z-index: 3;
    display: block;
    width: 100%;
}

.toggleSwitch input {
    position: absolute;
    opacity: 0;
    z-index: 5;
}

.left-span {
    order: 0;
    margin-right: 10px;
    font-weight: 700;
}

.right-span {
    order: 2;
    margin-left: 10px;
    font-weight: 700;
}

.toggleSwitch a {
    position: absolute;
    right: 50%;
    z-index: 4;
    display: block;
    top: 3px;
    bottom: 3px;
    padding: 0;
    left: 3px;
    right: 3px;
    width: 40%;
    background-color: #FFF;
    border-radius: 20px;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

.switch-wrapper .inactive {
    color: rgba(51, 51, 51, .33);
}

.switch-wrapper .active {
    color: var(--default-text-color);
}

.toggleSwitch>.odd-block:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    background-color: var(--green-go);
    border-radius: 20px;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

.toggleSwitch input:checked~a {
    left: calc(50%);
}

@media (max-width: 825px) {
    .description {
    }
}

@media (max-width: 425px) {
    .description {
    }
}

/* ---------------------------------------------------------------- */
/*
*   RULES STYLING
*/
/* ---------------------------------------------------------------- */

.description~.rules {
    font-weight: 400;
    line-height: 15px;
}

.wikipedia-link {
    color: var(--heading-color);
    font-weight: 700;
    text-transform: none;
    text-decoration: none;
    cursor: pointer;
    padding-bottom: 10px;
}

/* ---------------------------------------------------------------- */
/*
*   STATS STYLING
*/
/* ---------------------------------------------------------------- */
.stats-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 15px 2px 2px 2px;
}

.lost-total,
.won-total {
    font-size: 10px;
}

.total-games {
    justify-self: center;
    font-size: 15px;
    font-weight: 700;
    line-height: 150%;

}

.win-loss-bar {
    position: relative;
    width: 100%;
    background: #80C2ff;
    border-radius: 5px;
    height: 30px;

}

.color-bar {
    position: absolute;
    width: calc(100% - 4px);
    left: 2px;
    top: 2px;
    height: 26px;
    border-radius: 5px;
    background: linear-gradient(270deg, #037D00 0%, #04B000 0%, #FFBD13 5%, #E3005F 100%);
}

.red-percentage {
    font-size: 15px;
    font-weight: 600;
    color: white;
    position: absolute;
    top: 5.5px;
    left: 6px;

}

.green-percentage {
    font-size: 15px;
    font-weight: 600;
    color: white;
    position: absolute;
    top: 5.5px;
    right: 6px;
}

.stats-columns {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.stats-card {
    width: 100%;
    justify-content: center;
}


.stats-card .styled-table {
    width: 100%;
    min-width: 0;
}

.stats-modal .main-content {
    justify-content: center;
}

.stats-card>tr>td>div {
    width: 100%;
}

.stat-info {
    width: 50%;
}

.stat-info>div {
    text-align: right;
    font-weight: 700;
}

.stat-item {
    text-indent: 10px;
    width: 50%;
}

@media (max-width: 675px) {
    .stats-card {
        width: 100%;
    }
}