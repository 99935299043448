:root {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
  --gray: rgb(220, 220, 220);
  --green: rgb(95, 186, 124);
  --background: #005B78;
  /* RGB: 0, 91, 120 */
  --lemon: #FAF8BF;
  /* RGB: 250, 248, 191 */
  --darkBlue: ##00879D;
  /* 0, 135, 157 */
}

* {
  font-family: "Inter", "Arial, Helvetica, sans-serif";
}

html {
  max-width:100vw;
  max-height:100vh;
  margin:0;
  padding:0;
  max-height: -webkit-fill-available;
  max-width: -webkit-fill-available;
}

@media screen and (min-width: 200px) and (max-width: 499px) and (min-height: 0px) and (max-height: 940px) and (orientation: portrait) {

html {

    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@media screen and (min-width:500px) and (min-height: 941px) and (orientation: portrait) {

  html {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@media only screen and (orientation:landscape) {

html {

    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

header {
  background-color: rgba(255, 255, 255, .0);
  background: none;
}

body {
  overflow-x: hidden;
  overflow-y: hidden;
  touch-action: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin:0!important;
}

main {
  margin: 0;
  width: 100vw;
  height: 100vh;
}

/* ---------------------------------------------------------------- */
/*
*   NAVBAR STYLING
*/
/* ---------------------------------------------------------------- */

nav .navbar {
  background: transparent;
}

.navbar {
  width:350px;
  justify-content:center;
  align-items:center;
  position: absolute;
  bottom: 0px;
}

.navbar-brand .match {
  justify-content:center;
}

.container-fluid {
  width:350px;
  justify-content:center;
  align-items:center;
  padding-bottom:30px;
  padding-left:35px;
}

.navbar .brand {
  width: 336px;
  height: auto;
  margin-bottom:-15px;
}

.stats-container {
  display:flex; 
  flex-direction:row;
  flex-wrap:nowrap;
  justify-content:center;
  align-items:center;
}

.timer-block,
.points-block {
  display:flex;
  width: 100dppx;
  height: 30px;
  color: white;
  text-shadow: 0px 0px 10px #005B78;
  justify-content: center;
  align-items: center;
}

.timer,
.points {
  font-size: 15px;
  font-weight: 400;
}

.nav-divider {
  height:30px;
  align-self: center;
}

.button-container-desktop {
  display:none;
  position: fixed;
  right:40px;
  width:30px;
  display:block;
  text-align:right;
  padding: 35px 0px;
  z-index: 5;
}

/* ---------------------------------------------------------------- */
/*
*  BUTTON/ICON STYLING
*/
/* ---------------------------------------------------------------- */

.btn {
  cursor: pointer;
  font-weight: 400;
  line-height:1.5;
  text-align: center;
  background-color: transparent;
  border: none;
  color: var();
  text-decoration: none;
  vertical-align: middle;
}

button>img {
  vertical-align: middle;
  text-align: center;
}

.icon {
  background-color: transparent;
  border: none;
  margin-bottom:15px;
  /* height:20px; */
  width:auto;
}

.icon img {
  cursor: pointer;
  width:25px;
  height:auto;
}

.hide-this {
  visibility: hidden;
}

.close-icon-mobile {
  display:none;
}

/* ---------------------------------------------------------------- */
/*
*  MOBILE/TABLET STYLING
*/
/* ---------------------------------------------------------------- */
/* @media (max-width: 1200px) {
  .navbar .brand {
    max-width: 250px;
  }
}

@media (max-width: 1068px) {
  .navbar .brand {
    max-width: 215px;
  }
} */

/* @media (max-width: 768px) {
  .points-block {
    display: none;
  }

  .navbar .brand {
    width: 200px;
  }
} */

/* configure logo and buttons when screen goes 'mobile' */

@media screen and (min-width: 200px) and (max-width: 499px) and (min-height: 0px) and (max-height: 940px),
screen and (min-width:500px) and (max-width:940px) and (min-height:0px) and (max-height:499px) {

  .navbar{
   width:200px;
  };

  .container-fluid {
    display:none;
  }
  
  .navbar .mobile-container-fluid {
    width:200px;
    padding-left:20px;
    padding-bottom:20px;
  }
  .navbar .brand {
    width: 175px;
    height: auto;
    margin-bottom:-20px;
    margin-left:-10px;
  }

  .button-container-desktop {
    /* flex-direction: row;
    align-items: center;
    justify-content: flex-end; */
    display:none;
    width:225px;
    padding-top:0px;
    padding-bottom:20px;
    position: absolute;
    bottom:0;
    left:210px;
    text-align:left;
  }

  #btn-alerts {
    display:none;
  }



  .close-icon-mobile {
    display:block;
    position:relative;
    text-align:right;
    z-index:5;
  }

  #btn-alerts {
    display:none;
    padding:0;
    margin:0;
    position:absolute;
    top:2px;
    right:2px;
  }

  #btn-alerts img {
    width:18px;
    height:18px;
  }

  .button-container .timer-block {
    display: none;
  }

  .modal-content {
    margin: 10%;
  }

  .stats-container{
    display:none!important;
  }

}

/* @media (max-width: 500px) {

  .navbar-brand {
    flex-basis: 100%;
    justify-content: center;
  }

  .navbar .brand {
    width: 250px;
  }

  .button-container {
    flex-basis: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 0
  }

  .button-container .timer-label {
    text-align: left;
    order: -1;
  } */

/* } */